// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// https://github.com/gatsbyjs/gatsby/issues/1719
exports.shouldUpdateScroll = args => {
  return false
}

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)
// exports.onInitialClientRender = ({ location }) => scrollToAnchor(location)

/**
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const qs = document.querySelector(`${location.hash}`)
    if (qs) {
      const extra = 100 // TODO belongs in constants module
      const item = qs.offsetTop
      window.scrollTo({
        top: item - mainNavHeight - extra,
        // behavior: "smooth",
      })
    }
  }
  return true
}
